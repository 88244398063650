import { observer } from "mobx-react-lite";

import { CardType } from "../../shared/models/Card";
import { CardRelatedModel } from "../../stores/CardDetailPresenter";
import { HomeCardItem } from "../../stores/HomeStore";
import { UONCardItem } from "../../stores/UonCardDetailPresenter/UonCardDetailPresenter";
import { CardSharingOverlay } from "../CardSharingOverlay/CardSharingOverlay";
import { CardSharing } from "./CardSharing";

interface IProps {
  card: HomeCardItem | CardRelatedModel | UONCardItem;
}

export const LayoutCardSharing = observer(function LayoutCardSharing(
  props: IProps,
) {
  const { card } = props;
  if (
    card.contentType === CardType.ACTION ||
    card.contentType === CardType.INFO ||
    card.contentType === CardType.EDITABLE
  )
    return <></>;

  return (
    <>
      <CardSharing driver={card} />
      {card.shareActive && (
        <CardSharingOverlay onClick={() => card.toggleShareActive()} />
      )}
    </>
  );
});
