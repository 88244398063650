import { observer } from "mobx-react-lite";

import {
  CardType,
  CardTypeRegular,
  MasterLinkType,
} from "../../shared/models/Card";
import { ModalType } from "../../stores/ModalStore";
import { CardItemLocation } from "../CardItem/CardItem";
import {
  CardActionContextMenuDriver,
  CardItemActionContextMenu,
} from "../CardItemActionContextMenu/CardItemActionContextMenu";
import { LayoutDriver } from "../CardItemArticle/CardItemArticle";
import {
  CardItemEditableContextMenu,
  CardItemEditableContextMenuDriver,
} from "../CardItemEditable/CardItemEditableContextMenu";
import {
  CardInfoContextMenuDriver,
  CardItemInfoContextMenu,
} from "../CardItemInfoContextMenu/CardItemInfoContextMenu";
import { ContextMenuCardDelete } from "./ContextMenuCardDelete";
import { ContextMenuCardLayout } from "./ContextMenuCardLayout";
import { ContextMenuCardReport } from "./ContextMenuCardReport";
import { ContextMenuCardSave } from "./ContextMenuCardSave";
import { ContextMenuCardShare } from "./ContextMenuCardShare";
import { ContextMenuLayout } from "./ContextMenuLayout";

export interface CardProfileContextDriver {
  readonly isCardDeleteOptionVisible: boolean;
  readonly id: string;
  readonly contextMenuActive: boolean;
  readonly cardType: MasterLinkType;
  readonly contentType: CardTypeRegular;
  readonly deckPathString: string;
  readonly isCardOwner: boolean;
  readonly isCardLayoutEditorial: boolean;
  readonly isCardLayoutPhoto: boolean;
  readonly isCardLayoutText: boolean;
  readonly isCardArtical: boolean;
  onChangeCardLayoutEditorial(): void;
  onChangeCardLayoutPhoto(): void;
  onChangeCardLayoutText(): void;

  openContextMenu(): void;
  closeContextMenu(): void;
  onIconMoreClicked(): void;
  toggleShareActive(): void;
  updateCardDelete(): void;
  cardRepostIdUpdate(): void;
}

export interface CardDetailContextMenuProps {
  driver:
    | CardProfileContextDriver
    | CardActionContextMenuDriver
    | CardInfoContextMenuDriver
    | CardItemEditableContextMenuDriver;

  layoutDriver: LayoutDriver;
  location: CardItemLocation;
  openModal(modalType: ModalType): void;
}

export const CardDetailContextMenu = observer(function CardDetailContextMenu(
  props: CardDetailContextMenuProps,
) {
  const { driver, layoutDriver, location, openModal } = props;

  if (driver.contentType === CardType.ACTION) {
    return <CardItemActionContextMenu driver={driver} />;
  }
  if (driver.contentType === CardType.INFO) {
    return <CardItemInfoContextMenu driver={driver} />;
  }
  if (driver.contentType === CardType.EDITABLE) {
    return <CardItemEditableContextMenu driver={driver} />;
  }

  return (
    <ContextMenuLayout
      driver={driver}
      layoutDriver={layoutDriver}
      location={location}
      renderContextMenuItem={() => (
        <>
          {driver.isCardOwner ? (
            driver.isCardArtical && driver.isCardOwner ? (
              <ContextMenuCardLayout driver={driver} />
            ) : null
          ) : (
            <ContextMenuCardSave
              onSave={() => {
                driver.cardRepostIdUpdate();
                driver.closeContextMenu();
              }}
              isCardDeleteOptionVisible={driver.isCardDeleteOptionVisible}
            />
          )}

          <ContextMenuCardShare
            onShare={() => {
              driver.toggleShareActive();
              driver.closeContextMenu();
            }}
            isCardDeleteOptionVisible={driver.isCardDeleteOptionVisible}
          />
          {driver.isCardDeleteOptionVisible ? (
            <ContextMenuCardDelete
              onDelete={() => {
                driver.closeContextMenu();
                driver.updateCardDelete();
                openModal("cardDelete");
              }}
              location={location}
            />
          ) : (
            <ContextMenuCardReport
              onReport={() => {
                driver.closeContextMenu();
                openModal("cardReport");
              }}
            />
          )}
        </>
      )}
    />
  );
});
